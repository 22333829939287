<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 7C2 5.34315 3.34315 4 5 4H15C16.3062 4 17.4175 4.83481 17.8293 6H9C6.23858 6 4 8.23858 4 11V15.8293C2.83481 15.4175 2 14.3062 2 13V7ZM6 11C6 9.34315 7.34315 8 9 8H19C20.6569 8 22 9.34315 22 11V17C22 18.6569 20.6569 20 19 20H9C7.34315 20 6 18.6569 6 17V11ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13ZM11 14C11 12.3431 12.3431 11 14 11C15.6569 11 17 12.3431 17 14C17 15.6569 15.6569 17 14 17C12.3431 17 11 15.6569 11 14Z"
      fill="white"
    />
  </svg>
</template>
  
  <script>
import { Vue } from "vue-class-component";

export default class IconMoneyFill extends Vue {}
</script>
  